<template>
<section>
  <h1>{{$t('Nav.Publications')}} (12)</h1>
  <div id="resumeContent">

    <hr class="spacer"/>
    <h2>International Conference Publications (7)</h2>
    <PublicationLine
      title="TapStrapGest: Elicitation and Recognition for Ring-based Multi-Finger Gestures"
      authors="Guillem Cornella, Bruno Dumas, Mehdi Ousmer, Santiago Villarreal-Narvaez, Jean Vanderdonckt, Eudald Sangenis, Adrien Chaffangeon Caillet"
      conference="TBP in Proceedings of the 17th ACM SIGCHI Symposium on Engineering Interactive Computing Systems (EICS"
      :image="require('@/assets/paperImages/tapstrap.png')"
      paper-url=""
      file-url=""
      video-url=""
      :inverColor="true"
    />
    <PublicationLine
      title="MorphGrip: Haptic Guidance Using a Shape-Changing Grip"
      authors="Juan F. Olaya-Figueroa, Adrien Chaffangeon Caillet, Jan Willms, Marco Kurzweg, Nimer Darwiche, David Dann, Ferdinand Streicher, Katrin Wolf"
      conference="TBP in Proceedings of 2024 International Conference on Mobile and Ubiquitous Multimedia (MUM)"
      :image="require('@/assets/paperImages/morphgrip.png')"
      paper-url=""
      file-url=""
      video-url=""
      :inverColor="true"
    />
    <PublicationLine
      title="Resonant Sticker Buttons: The Effect of Button Size and Feedback Latency on Perceived Button Weight and Vibrotactile Feedback Strength"
      authors="Marco Kurzweg, Jan Willms, Adrien Chaffangeon Caillet, Katrin Wolf"
      conference="TBP in Proceedings of 2024 International Conference on Mobile and Ubiquitous Multimedia (MUM)"
      :image="require('@/assets/paperImages/resonantTouch.png')"
      paper-url=""
      file-url=""
      video-url=""
      :inverColor="true"
    />
    <PublicationLine
      title="Microgesture+ Grasp: A journey from human capabilities to interaction with microgestures"
      authors="Adrien Chaffangeon Caillet, Alix Goguey, Laurence Nigay"
      conference="International Journal of Human-Computer Studies"
      :image="require('@/assets/paperImages/microgesteGrasp.png')"
      paper-url="https://doi.org/10.1016/j.ijhcs.2024.103398"
      file-url="https://hal.science/hal-04801105v1"
      video-url=""
      :inverColor="true"
    />
    <PublicationLine
      title="3D Selection in Mixed Reality: Designing a Two-Phase Technique To Reduce Fatigue"
      authors="Adrien Chaffangeon Caillet, Alix Goguey, Laurence Nigay"
      conference="Proceedings of 2023 IEEE International Symposium on Mixed and Augmented Reality (ISMAR)"
      :image="require('@/assets/paperImages/ismar2023.svg')"
      paper-url="https://doi.org/10.1109/ISMAR59233.2023.00095"
      file-url="https://hal.science/hal-04297966v1"
      video-url=""
      :inverColor="true"
    />
    <PublicationLine
      title="Studying the Visual Representation of Microgestures"
      authors="Vincent Lambert, Adrien Chaffangeon Caillet, Alix Goguey, Sylvain Malacria, Laurence Nigay"
      conference="Proceedings of 25th International Conference on Human-Computer Interaction with Mobile Devices and Services (MobileHCI 2023)"
      :image="require('@/assets/paperImages/mobileHCI23.svg')"
      paper-url="https://doi.org/10.1145/3604272"
      file-url="https://theses.hal.science/INRIA-LILLE/hal-04193374v1"
      video-url="https://www.youtube.com/watch?v=eyyLyt5Fcas"
      :inverColor="true"
    />
    <PublicationLine
      title="µGlyph: a Microgesture Notation"
      authors="Adrien Chaffangeon Caillet, Alix Goguey, Laurence Nigay"
      conference="Proceedings of the 2023 CHI Conference on Human Factors in Computing Systems (CHI '23)"
      :image="require('@/assets/paperImages/mGlyphe.svg')"
      paper-url="https://doi.org/10.1145/3544548.3580693"
      file-url="https://hal.science/hal-04026125"
      video-url="https://www.youtube.com/watch?v=EhIkkgqDjUg"
      :inverColor="true"
    />

    <h2>Domestic Conference Publications (1)</h2>
    <PublicationLine
      title="µGlyph: a Graphical Notation to Describe Microgestures"
      authors="Adrien Chaffangeon, Alix Goguey, Laurence Nigay"
      conference="À la 33e conférence francophone sur l'Interaction Humain-Machine (IHM 2022)"
      :image="require('@/assets/paperImages/mGlyphe.svg')"
      paper-url="https://dl.acm.org/doi/fullHtml/10.1145/3500866.3516371"
      file-url="https://hal.science/hal-03655062v1"
      :inverColor="true"
    />

    <h2>Workshop (1)</h2>
    <PublicationLine
      title="Shape-Change in Keyboard Interaction: Exploring the Future of Input Devices Through Prototyping"
      authors="Laura Pruszko, Zhuzhi Fan, Adrien Chaffangeon Caillet"
      conference="TBP in 19th International Conference on Tangible Embedded and Embodied Interaction"
      :image="require('@/assets/paperImages/keyboard.png')"
      paper-url=""
      file-url="TBP in "
      :inverColor="true"
    />

    <h2>Demo (1)</h2>
    <PublicationLine
      title="µPoly : une Boîte à Outils pour Concevoir l'Interaction par Microgeste"
      authors="Adrien Chaffangeon Caillet, Aurélien Connil, Alix Goguey, Laurence Nigay"
      conference="À la 35e conférence francophone sur l'Interaction Humain-Machine (IHM 2024)"
      :image="require('@/assets/paperImages/mPoly.svg')"
      paper-url="https://hal.science/IHM-2024/hal-04499957"
      file-url="https://hal.science/IHM-2024/hal-04499957"
      :inverColor="true"
    />


    <h2>Art&Performance (1)</h2>
    <PublicationLine
      title="The not-so-masochist teapot"
      authors="Adrien Chaffangeon Caillet, Jasper Flügge, Eric Chaffangeon, Katrin Wolf"
      conference="TBP in 19th International Conference on Tangible Embedded and Embodied Interaction"
      :image="require('@/assets/paperImages/teapot.jpeg')"
      paper-url=""
      file-url="TBP in "
      :inverColor="true"
    />

    <h2>Thesis (1)</h2>
    <PublicationLine
      title="Comprendre et concevoir l'interaction par microgeste"
      authors="Adrien Chaffangeon Caillet"
      conference="PhD thesis (Université Grenoble Alpes - December 2023)"
      :image="require('@/assets/paperImages/BD-small.png')"
      paper-url="https://www.theses.fr/s245414"
      file-url="https://hal.science/tel-04359801"
      video-url="https://youtu.be/mXHUplu9ctg?si=YaiK0u58nouYgPEo"
      :inverColor="true"
    />
  </div>
</section>
</template>

<script>
import PublicationLine from '@/components/Lines/PublicationLine'

export default {
  name: 'Publications',
  components: { PublicationLine }
}
</script>

<style scoped>

h2 {
  margin-top: 70px;
}

</style>
