<template>
  <section id="main">
  <Round_Picture/>
  <div id="content">
    <h1>{{$t('Home.Presentation')}}</h1>
    <p>
    <template v-if="$i18n.locale === 'fr'">
      En septembre 2024, j'ai commencé à travailler en tant que maître de conférences à <a href="https://iae.grenoble-inp.fr">Grenoble IAE - INP</a> et est devenu membre permanent de l'équipe de recherche <a href="http://iihm.imag.fr">Ingénierie de l'Interaction Humain-Machine</a>.<br>
      De janvier à août 2024, j'ai travaillé comme chercheur postdoctoral avec la professeure <a href="https://katrinwolf.info/">Katrin Wolf</a> à <a href="https://www.bht-berlin.de/">Berliner Hochschule für Technik</a>.
      Mon travail s'est concentré sur le <i>pervasive touch</i> et les objets à changement de formes.<br>
      En décembre 2023, j'ai obtenu mon doctorat à l'Université de Grenoble Alpes dans l'équipe <a href="http://iihm.imag.fr/">Ingénierie de l'Interaction Humain-Machine</a>. Un résumé de mon doctorat est disponible sous forme de bande dessinée <a href="./files/BD.PNG">ici</a>. Mes travaux ont porté sur l'utilisation des microgestes pour interagir en réalité augmentée. J'ai enseigné à Polytech Grenoble, à l'Université Grenoble Alpes et à l'IUT MMI Grenoble.
    </template>
    <template v-else>
      In September 2024, I started working as an assistant professor at <a href="https://iae.grenoble-inp.fr/en">Grenoble IAE - INP</a> and became a permanent member of the <a href="http://iihm.imag.fr/en/">Engineering Human-Computer Interaction</a> research group.<br>
      Since May 2024, I have been a scientific collaborator at the <a href="https://uclouvain.be/en/research-institutes/lourim">Louvain Research Institute in Management and Organisation</a>.
      From January 2024 to August 2024, I was working as a postdoctoral fellow with Pr <a href="https://katrinwolf.info/">Katrin Wolf</a> at the <a href="https://www.bht-berlin.de/">Berliner Hochschule für Technik</a>.
      My focus was on pervasive touch and shape changing objects.<br>
      In December 2023, I received my PhD at the University of Grenoble Alpes in the <a href="http://iihm.imag.fr/en/">Engineering Human-Computer Interaction</a> research group. A summary of my PhD is available as a comic strip <a href="./files/BD-en.png">here</a>. My focus was on the use of microgestures to interact in augmented reality. I was teaching at Polytech Grenoble, Université Grenoble Alpes and IUT MMI Grenoble.
    </template>
    </p>

    <p v-if="false">
      <template v-if="$i18n.locale === 'fr'">
        De Septembre 2019 à Septembre 2020, j'étais étudiant en master à l'ENS de Rennes en section informatique, et étudiant en master à l'Université Paris-Saclay en interaction humain machine.
      </template>
      <template v-else>
        From September 2019 to September 2020, I was a master student at ENS de Rennes in computer science and a master student at Université Paris-Saclay in human computer interaction.
      </template>
    </p>
  </div>
  <div id="news-container">
    <h1>News</h1>
    <ul v-if="$i18n.locale === 'fr'">
      <li>
        1 papier démo accepté à IHM 2024.
      </li>
      <li>
        1 papier accepté à ISMAR 2023: <i>3D Selection in Mixed Reality: Designing a Two-Phase Technique To Reduce Fatigue</i>.
      </li>
      <li>
        La travail de <a href="https://vincent-lambert.gitlab.io/website/">Vincent Lambert</a> sur la représentation des microgestes a été accepté à MobileHCI'23.
      </li>
      <li>
        1 papier accepté à CHI 2023: <i>µGlyph: a Microgesture Notation</i>.
      </li>
    </ul>
    <ul v-else>
      <li>I will be co-hosting a studio on changing keyboards at TEI2025 with Laura Pruszko -- GCU, Glasgow -- and Zhuzhi Fan -- University of Bristol. I will also be presenting an art installation entitled <i>the not-so-masochist teapot</i> done in collaboration with Katrin Wolf -- BHT, Berlin -- and 3D Modelage&Conception</li>
      <li>
        1 paper published in IJHCS: <i>Microgesture+ Grasp: A journey from human capabilities to interaction with microgestures</i>
      </li>
      <li>
        Juan Olaya-Figueroa's work on haptic guidance and Marco Kurzweg's on vibrotactile feedback perception have both been accepted to MUM 2024.
      </li>
      <li>
        1 paper accepted to EICS 2025 in collaboration with Jean Vanderdonckt from the LouRIM - UCLouvain.
      </li>
      <li>
        1 demo paper accepted to IHM 2024.
      </li>
      <li>
        1 paper accepted to ISMAR 2023: <i>3D Selection in Mixed Reality: Designing a Two-Phase Technique To Reduce Fatigue</i>.
      </li>
      <li>
        The work of <a href="https://vincent-lambert.gitlab.io/website/">Vincent Lambert</a> on the visual representation of microgestures has been accepted to MobileHCI'23.
      </li>
      <li>
        1 paper accepted to CHI 2023: <i>µGlyph: a Microgesture Notation</i>.
      </li>
    </ul>
  </div>
  </section>
</template>

<script>

import Round_Picture from '@/components/Round_Picture'
export default {
  name: 'Home',
  components: {
    Round_Picture
  },
  data () {
    return {
      pressed: false
    }
  }
}
</script>

<style>
.round-img-container {
  max-width: 200px;
  max-height: 200px;
}

.round-img-container img {
  max-height: 200px;
}

@media (min-width: 768px) {
  .round-img-container {
    max-width: 400px;
    max-height: 400px;
  }

  .round-img-container img {
    max-height: 400px;
  }

  #content {
    float: left;
    position: relative;
    margin-top: 50px;
  }
}
</style>
